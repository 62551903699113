import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import BodyText from 'stoomlink-commons-ui/BodyText/BodyText';
import Title from 'stoomlink-commons-ui/Title';
import { colors } from '../theme/index';

import Container from '../layouts/Container';
import Layout from '../layouts/Layout';
import Head from '../layouts/Head';

import Footer from '../components/Footer';
import Hero from '../components/Hero';
import MailForm from '../components/MailForm';
import List from '../components/List';

// =============================================================================
// Content
// =============================================================================

import about from '../../content/about';

const { team } = about;

// =============================================================================
// Styles
// =============================================================================

const PostContent = styled.div`
  margin-bottom: 64px;
  
  .about-content ul {
    margin-top: 32px;
  }
  h2 {
    margin: 64px 0 32px 0;
    font-size: 40px;
    line-height: 44px;
    @media screen and (max-width: 768px){
      font-size: 32px;
    }
  }
  p, .blog-post-content li {
    width: 56%;
    font-weight: 500;
    line-height: 24px;
    @media screen and (max-width: 768px){
      width: 100%;
    }
  }
  .blog-post-content ul {
    padding-left: 28px;
  }
  .blog-post-content li {
    position: relative;
    margin-top: 12px;
  }
  .blog-post-content li::before {
    position: absolute;
    left: -18px;
    content: "•"; 
    color: ${colors.main};
    font-size: 25px;
  } 
  p {
    margin-bottom: 24px;
  }
  li p {
    width: 100%;
    margin-bottom: 0px;
    font-weight: 400;
  }
  a {
    text-decoration: none;
    position: relative;
    display: inline-block;
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background: ${colors.main};
      display: block;
      position: absolute;
      bottom: -1px;
      transform: scaleX(0);
      transform-origin: center left;
      transition: all .3s ease-in;
    }
    &:hover {
      &::after{
        transform: scaleX(1);
      }
    }
  }
`;

const MailCard = styled.div`
  background: ${colors.accent};
  padding: 50px 102px;
  @media (max-width: 1340px){
    padding: 50px 51px;
  }
  @media (max-width: 767px){
    padding: 0;
  }
`;

const MailContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 980px){
    flex-direction: column;
  }
  form, h1 {
    position: inherit;
    width: 50%;
    @media screen and (max-width: 980px){
      width: 100%;
    }
  }
  h1 {
    padding: 0 51px 50px 0;
    @media screen and (max-width: 767px){
      padding: 32px;
      max-width: 400px;
    }
    @media screen and (max-width: 980px){
        
    }
  }
`;

// =============================================================================
// Page
// =============================================================================

function PostPage({
  data,
}) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const {
    headTitle,
    title,
    suptitle,
    details,
    intro,
    actionTitle,
    actionContent,
    actionButtonTitle,
    actionDisplay,
  } = frontmatter;

  const mailPostTitle = `is applying for the ${headTitle} post 🚀`;
  const mailPostObject = `${headTitle} - application`;

  const pageTitle = (suptitle) ? `${headTitle} ${suptitle}` : title;
  return (
    <ThemeProvider themeStyle="light">
      <Layout>
        <Head title={pageTitle} />
        <Hero suptitle={suptitle} title={title}>
          {details && (
            <BodyText type="eyebrow" style={{ marginBottom: '24px' }}>{details}</BodyText>
          )}
          <BodyText type="body" style={{ fontWeight: '400' }}>{intro}</BodyText>
        </Hero>

        <Container>
          <PostContent>
            <div className="about-content">
              {team.map((item) => {
                return (
                  <div key={`${item.title}`}>
                    <Title level="2" dangerouslySetInnerHTML={{ __html: item.title }} />
                    <BodyText type="body" dangerouslySetInnerHTML={{ __html: item.content }} />
                    {item.list && (<List items={item.list} theme="secondary" />)}
                  </div>
                );
              })}
            </div>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </PostContent>

          <MailCard>
            <MailContainer>
              <Title displayLevel={3} dangerouslySetInnerHTML={{ __html: actionDisplay }} />
              <MailForm
                title={actionTitle}
                smallTitle={actionContent}
                buttonTitle={actionButtonTitle}
                mailTitle={mailPostTitle}
                mailObject={mailPostObject}
              />
            </MailContainer>
          </MailCard>

        </Container>
        <Footer teamLink={false} />
      </Layout>
    </ThemeProvider>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        headTitle
        title
        suptitle
        details
        intro
        actionTitle
        actionContent
        actionButtonTitle
        actionDisplay
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;

export default PostPage;
