const about = {
  team: [
    {
      title: 'About <span class="focus-main">us</span>.',
      content: 'We’re Belgian Mobility Company, a cooperative initiative of the 4 Belgian Public Transports Operators ⏤ TEC, STIB-MIVB, DeLijn and NMBS-SNCB. As a small & decentralized team, we foster digital innovation to ease the journey of millions of public transport customers.',
      list: [
        {
          title: '',
          content: 'BMC was founded in 2010 as a joint-venture company of the four Belgian Public Transport Operators, namely TEC (Wallonia transit network), STIB-MIVB (Brussels transit network), SNCB-NMBS (National rail network) and De Lijn (Flanders transit network). ',
        },
        {
          title: '',
          content: 'Initially, BMC aimed to create the MoBIB card, an intermodal payment system for all Belgian travellers. In early 2022, BMC expanded significantly by acquiring Stoomlink, a startup renowned for its advanced mobility digital products.',
        },
        {
          title: '',
          content: 'Stoomlink\'s portfolio includes the award-winning NextRide app and the Smart Mobility Planner, an ambitious open-source route planner built on cutting-edge technology and exceptional user experience.',
        },
      ],
    },
    {
      title: 'What we do.',
      content: 'Switching to sustainable mobility is challenging, largely due to a lack of confidence in alternative options and the discomfort of giving up personal cars. To address this, we focus on delivering high-quality, real-time, contextualized information to the travellers in Belgium through a range of innovative digital solutions.',
    },
    {
      title: 'Our DNA',
      content: '<p>We are not only great product makers: we also like to think we are the best team 💪 And this has something to do with our DNA and our willingness to (re)invent the way we work. And if concepts like work/life balance, autonomy and structured freedom are important to you, go ahead to <a class="focus-main" href="http://open.stoomlink.io" target="_blank"> our public DNA page</a> to get to know us a bit better!</p> <p>Oh, one more thing! You’ll see in there that inclusivity is an integral part of our cherished values. And as we don’t repeat it enough: let’s break the gender gap together, women are strongly encouraged to apply 🔥.</p>',
    },
  ],
};

export default about;
